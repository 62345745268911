/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimulateEvmTransactionRequest
 */
export interface SimulateEvmTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof SimulateEvmTransactionRequest
     */
    chainId: string;
    /**
     * 
     * @type {string}
     * @memberof SimulateEvmTransactionRequest
     */
    data: string;
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof SimulateEvmTransactionRequest
     */
    from: string;
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof SimulateEvmTransactionRequest
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof SimulateEvmTransactionRequest
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof SimulateEvmTransactionRequest
     */
    domain?: string;
}

export function SimulateEvmTransactionRequestFromJSON(json: any): SimulateEvmTransactionRequest {
    return SimulateEvmTransactionRequestFromJSONTyped(json, false);
}

export function SimulateEvmTransactionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulateEvmTransactionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chainId': json['chainId'],
        'data': json['data'],
        'from': json['from'],
        'to': json['to'],
        'value': json['value'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
    };
}

export function SimulateEvmTransactionRequestToJSON(value?: SimulateEvmTransactionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chainId': value.chainId,
        'data': value.data,
        'from': value.from,
        'to': value.to,
        'value': value.value,
        'domain': value.domain,
    };
}

