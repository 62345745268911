import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';
import { useGetEnvironmentProviders } from '@dynamic-labs/redcoast-query';

import { FullScope } from '../../data/subscription';
import {
  useEnvironmentId,
  useManipulateProviderState,
  type UseManipulateProviderStateReturnValue,
} from '../../routes/Configurations/Providers/hooks';
import { useProvidersRedirectUrlsQuery } from '../../hooks/useProvidersRedirectUrlsQuery';
import { useSubscriptionLock } from '../../hooks/useSubscriptionLock';
import { convertProvidersToMap } from '../../routes/Configurations/Providers/utils';

type ProvidersContextType = Omit<
  UseManipulateProviderStateReturnValue,
  'currentProviders' | 'setProviderValue'
> & {
  availableSocialProviders: ProviderEnum[];
  changedProviders: Provider[];
  enabledProviders: Provider[];
  enabledSocialProviders: Provider[];
  getProviderBasicScopes: (provider: ProviderEnum) => string | undefined;
  getProviderRedirectUrl: (provider: ProviderEnum) => string | undefined;
  hasChanges: boolean;
  isRefetching: boolean;
  refetch: () => void;
  savedProviders: Record<ProviderEnum, Provider>;
};

type Props = {
  children: ReactNode;
};

export const ProvidersContext = createContext<ProvidersContextType | undefined>(
  undefined,
);

export const ProvidersContextProvider = ({ children }: Props) => {
  const environmentId = useEnvironmentId();

  const { data: providersRedirectUrls } =
    useProvidersRedirectUrlsQuery(environmentId);

  const {
    data: providersData,
    refetch,
    isRefetching,
  } = useGetEnvironmentProviders(
    {
      environmentId: environmentId || '',
    },
    {
      enabled: !!environmentId,
    },
  );

  const { shouldLockOnLive } = useSubscriptionLock(FullScope.InfoCapture);

  const savedProviders = useMemo<Record<ProviderEnum, Provider>>(
    () => convertProvidersToMap(providersData?.providers || []),
    [providersData],
  );

  const getProviderRedirectUrl = useCallback(
    (provider: ProviderEnum) =>
      providersRedirectUrls?.providerUrls?.find(
        (url) => url.provider === provider,
      )?.redirectUrl,
    [providersRedirectUrls],
  );

  const getProviderBasicScopes = useCallback(
    (provider: ProviderEnum) =>
      providersRedirectUrls?.providerUrls?.find(
        (url) => url.provider === provider,
      )?.scopes,
    [providersRedirectUrls],
  );

  const {
    toggleProvider,
    isProviderEnabled,
    onChangeProvider,
    hasChanges,
    providers,
    getProviderValue,
    resetProviderState,
    hasProviderChanged,
  } = useManipulateProviderState(savedProviders, shouldLockOnLive);

  const availableSocialProviders = useMemo(
    () =>
      providersRedirectUrls?.providerUrls
        ? providersRedirectUrls.providerUrls
            .filter((provider) => provider.redirectUrl)
            .map((provider) => provider.provider)
        : [],
    [providersRedirectUrls?.providerUrls],
  );

  const enabledSocialProviders = useMemo(
    () =>
      providers.filter(
        (provider) =>
          Boolean(provider.enabledAt) &&
          availableSocialProviders.includes(provider.provider),
      ),
    [availableSocialProviders, providers],
  );

  const enabledProviders = useMemo(
    () => providers.filter((provider) => Boolean(provider.enabledAt)),
    [providers],
  );

  const changedProviders = useMemo(
    () => providers.filter((provider) => hasProviderChanged(provider.provider)),
    [providers, hasProviderChanged],
  );

  const value = useMemo(
    () => ({
      availableSocialProviders,
      changedProviders,
      enabledProviders,
      enabledSocialProviders,
      getProviderBasicScopes,
      getProviderRedirectUrl,
      getProviderValue,
      hasChanges,
      hasProviderChanged,
      isProviderEnabled,
      isRefetching,
      onChangeProvider,
      providers,
      refetch,
      resetProviderState,
      savedProviders,
      toggleProvider,
    }),
    [
      availableSocialProviders,
      changedProviders,
      enabledProviders,
      enabledSocialProviders,
      getProviderRedirectUrl,
      getProviderBasicScopes,
      getProviderValue,
      hasChanges,
      hasProviderChanged,
      isProviderEnabled,
      isRefetching,
      onChangeProvider,
      providers,
      refetch,
      resetProviderState,
      savedProviders,
      toggleProvider,
    ],
  );

  return (
    <ProvidersContext.Provider value={value}>
      {children}
    </ProvidersContext.Provider>
  );
};

// ## useProvidersContext
// Use this context hook to get, set, and manipulate the state of all providers.
//
// This will affect only providers using the Providers table to store the values.
// Use it to manipulate enabledAt state and credentials of providers.
//
// ### IMPORTANT NOTE!
//
// If you want to manipulate enabled state for Magic or Dynamic **SIGN IN (only)** providers
// use `useSignInWithSocialProviders` hook. Their enabled state is maintain in the sdk settings.
//
export const useProvidersContext = () => {
  const context = useContext(ProvidersContext);

  if (context === undefined) {
    throw new Error(
      'usage of useProvidersContext not wrapped in `ProvidersContextProvider`.',
    );
  }

  return context;
};
