import { FC } from 'react';

// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';
import { t as staticTMethod } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { Input, Typography } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../../../app/context/ProvidersContext';
import { SingleKey } from '../../../../../../../../../app/components/SingleKey';

import styles from './OwnCredentialsForm.module.scss';

type OwnCredentialsFormProps = {
  provider: ProviderEnum;
};

const requiredText = staticTMethod(
  'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.fields.required',
);

const schema = yup.object({
  clientId: yup.string().required(requiredText),
  clientSecret: yup.string().required(requiredText),
  scopes: yup.string().required(requiredText),
});

type CredentialsForm = yup.InferType<typeof schema>;

type FieldTypes = 'clientId' | 'clientSecret' | 'scopes';
const FIELDS: FieldTypes[] = ['clientId', 'clientSecret', 'scopes'];

export const OwnCredentialsForm: FC<OwnCredentialsFormProps> = ({
  provider,
}) => {
  const { t } = useTranslation();

  const {
    getProviderValue,
    onChangeProvider,
    getProviderRedirectUrl,
    getProviderBasicScopes,
  } = useProvidersContext();

  const methods = useForm<CredentialsForm>({
    defaultValues: {
      clientId: getProviderValue(provider, 'clientId'),
      clientSecret: getProviderValue(provider, 'clientSecret'),
      scopes: getProviderValue(provider, 'scopes'),
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    register,
    getValues,
    formState: { errors },
  } = methods;

  const handleOnBlur = () => {
    if (errors.clientId || errors.clientSecret || errors.scopes) return;

    onChangeProvider(provider, 'clientId', getValues('clientId'));
    onChangeProvider(provider, 'clientSecret', getValues('clientSecret'));
    onChangeProvider(provider, 'scopes', getValues('scopes'));
  };

  const fieldsToUse =
    provider === ProviderEnum.Telegram
      ? FIELDS.filter((f) => f !== 'scopes')
      : FIELDS;

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onBlur={handleOnBlur} className={styles.form}>
          {fieldsToUse.map((field) => (
            <div key={field}>
              <Input
                name={field}
                onBlur={register(field).onBlur}
                onChange={register(field).onChange}
                ref={register(field).ref}
                error={!!errors[field]}
                label={
                  provider === ProviderEnum.Telegram
                    ? t(
                        `v2.page.log_in_methods.social.drawer.form.telegram.${field}`,
                      )
                    : t(`v2.page.log_in_methods.social.drawer.form.${field}`)
                }
              />
              {errors[field]?.message && (
                <Typography
                  variant='paragraph-1'
                  color='inherit'
                  className={styles.error}
                >
                  {errors[field]?.message}
                </Typography>
              )}
            </div>
          ))}
        </form>
      </FormProvider>

      <SingleKey
        className={styles['redirect-url']}
        title={
          provider === ProviderEnum.Telegram
            ? t(
                'v2.page.log_in_methods.social.drawer.form.telegram.redirectUrl',
              )
            : t('v2.page.log_in_methods.social.drawer.form.redirectUrl')
        }
        keyValue={getProviderRedirectUrl(provider) || ''}
      />

      {provider !== ProviderEnum.Telegram && (
        <SingleKey
          title={t('v2.page.log_in_methods.social.drawer.form.basicScopes')}
          hideCopy
          keyValue={getProviderBasicScopes(provider) || ''}
        />
      )}
    </>
  );
};
