/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetDiff,
    AssetDiffFromJSON,
    AssetDiffFromJSONTyped,
    AssetDiffToJSON,
} from './AssetDiff';

/**
 * 
 * @export
 * @interface SimulateEvmTransactionResponse
 */
export interface SimulateEvmTransactionResponse {
    /**
     * 
     * @type {Array<AssetDiff>}
     * @memberof SimulateEvmTransactionResponse
     */
    inAssets: Array<AssetDiff>;
    /**
     * 
     * @type {Array<AssetDiff>}
     * @memberof SimulateEvmTransactionResponse
     */
    outAssets: Array<AssetDiff>;
}

export function SimulateEvmTransactionResponseFromJSON(json: any): SimulateEvmTransactionResponse {
    return SimulateEvmTransactionResponseFromJSONTyped(json, false);
}

export function SimulateEvmTransactionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulateEvmTransactionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inAssets': ((json['inAssets'] as Array<any>).map(AssetDiffFromJSON)),
        'outAssets': ((json['outAssets'] as Array<any>).map(AssetDiffFromJSON)),
    };
}

export function SimulateEvmTransactionResponseToJSON(value?: SimulateEvmTransactionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inAssets': ((value.inAssets as Array<any>).map(AssetDiffToJSON)),
        'outAssets': ((value.outAssets as Array<any>).map(AssetDiffToJSON)),
    };
}

