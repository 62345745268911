/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvmAssetTransfer
 */
export interface EvmAssetTransfer {
    /**
     * 
     * @type {string}
     * @memberof EvmAssetTransfer
     */
    rawValue: string;
    /**
     * 
     * @type {string}
     * @memberof EvmAssetTransfer
     */
    summary: string;
    /**
     * 
     * @type {string}
     * @memberof EvmAssetTransfer
     */
    usdPrice: string;
    /**
     * 
     * @type {string}
     * @memberof EvmAssetTransfer
     */
    value: string;
}

export function EvmAssetTransferFromJSON(json: any): EvmAssetTransfer {
    return EvmAssetTransferFromJSONTyped(json, false);
}

export function EvmAssetTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvmAssetTransfer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rawValue': json['rawValue'],
        'summary': json['summary'],
        'usdPrice': json['usdPrice'],
        'value': json['value'],
    };
}

export function EvmAssetTransferToJSON(value?: EvmAssetTransfer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rawValue': value.rawValue,
        'summary': value.summary,
        'usdPrice': value.usdPrice,
        'value': value.value,
    };
}

