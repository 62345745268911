/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvmAsset,
    EvmAssetFromJSON,
    EvmAssetFromJSONTyped,
    EvmAssetToJSON,
} from './EvmAsset';
import {
    EvmAssetTransfer,
    EvmAssetTransferFromJSON,
    EvmAssetTransferFromJSONTyped,
    EvmAssetTransferToJSON,
} from './EvmAssetTransfer';

/**
 * 
 * @export
 * @interface AssetDiff
 */
export interface AssetDiff {
    /**
     * 
     * @type {EvmAsset}
     * @memberof AssetDiff
     */
    asset: EvmAsset;
    /**
     * 
     * @type {Array<EvmAssetTransfer>}
     * @memberof AssetDiff
     */
    _in: Array<EvmAssetTransfer>;
    /**
     * 
     * @type {Array<EvmAssetTransfer>}
     * @memberof AssetDiff
     */
    out: Array<EvmAssetTransfer>;
}

export function AssetDiffFromJSON(json: any): AssetDiff {
    return AssetDiffFromJSONTyped(json, false);
}

export function AssetDiffFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetDiff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': EvmAssetFromJSON(json['asset']),
        '_in': ((json['in'] as Array<any>).map(EvmAssetTransferFromJSON)),
        'out': ((json['out'] as Array<any>).map(EvmAssetTransferFromJSON)),
    };
}

export function AssetDiffToJSON(value?: AssetDiff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': EvmAssetToJSON(value.asset),
        'in': ((value._in as Array<any>).map(EvmAssetTransferToJSON)),
        'out': ((value.out as Array<any>).map(EvmAssetTransferToJSON)),
    };
}

